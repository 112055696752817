@import "global/variables";

:host {
  //@include spacer;
  margin-bottom: calc(#{$spacer} * 0.5);

  @media print {
    display: none !important;
  }

  /*.mat-mdc-button,
  .mat-mdc-unelevated-button,
  .mat-mdc-outlined-button,
  .mat-mdc-raised-button,*/
  .mat-mdc-button-base,
  .mat-mdc-fab-base {
    font-family: var(--sys-body-medium-font);
    font-size: var(--sys-body-medium-size);
    //color: var(--ds-font-button-label-color, var(--sys-on-primary));
    font-weight: var(--ds-font-button-weight, 400);
    border-radius: var(--ds-radius, $mat-radius);
    //letter-spacing: normal;
  }

  /*  .mat-mdc-flat-button,
  .mat-mdc-raised-button,
  .mat-mdc-stroked-button,
  .mat-mdc-fab,
  .mat-mdc-accent,
  .mat-mdc-mini-fab
  */
  .mat-mdc-button-base,
  .mat-mdc-fab-base {
    // color: var(--ds-font-button-label-color);
    &.mat-primary:not([disabled]),
    &.mat-accent:not([disabled]) {
      // color: var(--ds-font-button-label-color);
    }
  }
  .mat-mdc-mini-fab {
    &.mat-primary:not([disabled]) {
      // color: var(--ds-font-button-label-color);
    }
  }

  .mat-icon {
    // color: var(--ds-font-button-label-color);
    width: unset;
    height: unset;
    overflow: unset;
    font-size: 90%;
  }

  .mat-mdc-fab:not(.mdc-fab--extended) .mat-icon {
    font-size: 1.5rem;
  }

  .icon-prefix {
    margin-left: -4px;
    margin-right: 8px;
    //margin-right: calc(#{$spacer} * 0.25);
    //text-align: left;
  }

  .icon-postfix {
    margin-left: 8px;
    margin-right: -4px;
    //   margin-left: calc(#{$spacer} * 0.25);
    // text-align: right;
  }
}

.mat-mdc-outlined-button {
  // background-color: rgba($color: white, $alpha: 0.15);
}

// Mat button textured

.mat-button-textured {
  background-image: var(--ds-textured-button-url);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

// Mat button modifier for footer compatibility

:host-context(.mat-footer) .mat-mdc-button-base {
  @include mat-caption;
  padding: 0.5rem 1rem;
  height: 32px !important;
}

// button-container-row component responsiveness behavior
:host-context(.buttonContainerRow) {
  margin-bottom: calc(#{$spacer} * 0.5);

  @include breakpoint {
    @include spacer;
  }

  button,
  a {
    //  @include block;

    @include breakpoint {
      width: auto;
    }
  }
}

// isStacked buttons container default behavior
:host-context(.isStacked) {
  // @include block;
  width: auto;
  margin-bottom: calc(#{$spacer} * 0.5);

  @include breakpoint {
    // max-width: $mat-button-max-width;
  }

  button,
  a {
    width: 100%;
    //  @include block;
  }
}

// Fixed size buttons with a max width
:host-context(.fixed-size) {
  // @include block;
  display: flex;
  width: 100%;

  button,
  a {
    //  @include block;
    width: 100%;

    @include breakpoint {
      max-width: $mat-button-max-width;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

// Full size buttons with no max width
:host-context(.full-size) {
  // @include block;
  width: 100%;

  button,
  a {
    //    @include block;
    width: 100%;
  }
}
