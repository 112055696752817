// FONT

/* .mat-mdc-input-element,
.mdc-label,
.mdc-floating-label,
.mat-mdc-form-field-hint,
.mat-mdc-select-value-text {
  font-family: var(--sys-body-medium-font, $mat-font-family) !important;

}

.mat-mdc-form-field-error {
  font-family: var(--sys-body-medium-font, $mat-font-family) !important;
} */

// FORM ITEMS SPACING
.mat-mdc-form-field {
  margin-top: calc(#{$spacer} * 0.25) !important;
  margin-bottom: calc(#{$spacer} * 0.5) !important;
}

// CHECKBOX & RADIO & TOGGLE

.mat-mdc-checkbox,
.mat-mdc-radio-button {
  margin-right: calc(#{$spacer} * 1);
}

.mdc-switch {
  margin-right: calc(#{$spacer} * 0.5);
}

/* .mat-mdc-form-field-type-checkbox,
//.mat-mdc-form-field-type-multicheckbox,
.mat-mdc-form-field-type-toggle {
  .mdc-notched-outline {
    display: none;
  }

  .mat-mdc-form-field-infix {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    min-height: auto !important;
  }
}

 */

// PHONE NUMBER INTL WRAPPER SELECT

/* div.mat-mdc-select-panel {
  min-width: 300px !important;
} */
