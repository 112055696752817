@import "global/variables";

// Custom design for native error page when the app fails

.native-error-body-adaption {
  margin: 0;
  padding: 0 1rem;
  text-align: center;
  background: grey;
  font-family: "Roboto", Arial, Helvetica, sans-serif;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  #app-native-container {
    width: 100%;
  }
}

.native-error-container {
  width: 100%;
  max-width: 20rem;
  height: auto;
  display: block;
  margin: 0 auto;
  padding: 2rem;
  color: black;
  background: white;
  border-radius: $mat-radius;
  box-sizing: border-box;
  overflow-wrap: break-word;
}

.native-error-image {
  max-width: 4rem;
  margin: 0 auto;

  img,
  svg {
    max-width: 100%;
    height: auto;
    display: block;
  }
}

.native-error-message {
  font-size: 1rem;
  margin: 1rem 0 0 0;
}
