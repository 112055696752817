// // @use 'sass:map';
// @use '@angular/material' as mat;
// @use '../main.scssm3-theme.scss' as theme;

// Default display
@mixin block {
  width: 100%;
  height: auto;
  display: block;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
}

// Fullscreen and fullsize display
@mixin fullsize {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

// Default horizontal spacer for all UI elements
@mixin spacer {
  margin-bottom: calc(#{$spacer * 1});
}

// Default side padding for screen view so UI is away from the side of the viewport to allow good readability
@mixin side-padding {
  padding-right: calc(#{$spacer} * 1);
  padding-left: calc(#{$spacer} * 1);
}

// Default responsive breakpoint for desktop. Mobile first approach.
@mixin breakpoint {
  @media screen and (min-width: 600px) {
    @content;
  }
}

// Default responsive breakpoint for mobile full width apps.
@mixin mobile-full-width {
  @media screen and (max-width: 600px) {
    padding-right: 0;
    padding-left: 0;
  }
}

// Box shadow
@mixin box-shadow {
  box-shadow: var(--mat-app-elevation-shadow-level-8, none);
}

// Absolute centering
@mixin centerer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// Element you can't click
@mixin noclick {
  background: transparent;
  pointer-events: none;
}

// Element you can't select
@mixin noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  /* mobile webkit */
  outline: none;
  /*No line around the outside of an element*/
}

// Text that won't wrap
@mixin nowrap {
  white-space: nowrap;
}

// Card for bg color to games

// @mixin card-wrap($theme) {
@mixin card-wrap {
  &.card-wrap {
    width: 100%;
    border-radius: var(--ds-radius, $mat-radius);
    background-color: var(--sys-surface);
    padding: calc(#{$spacer} * 1.5) $spacer $spacer $spacer;
    margin: 0;
    //@include box-shadow;

    @include breakpoint {
      padding: calc(#{$spacer} * 2);
    }
  }
}

// Default mat form styling app wide
@mixin mat-form {
  // Dynamic forms theming management for the form wrapper
  .mat-form {
    @include block;
    //width: calc(100% - #{$spacer} * 2);
    width: 100%;
    //margin: 0 $spacer;
    font-size: 16px;
    background-color: var(--sys-surface);
    border-radius: var(--ds-radius, $mat-radius);
    padding: calc(#{$spacer} * 1) calc(#{$spacer} * 1.25);

    @include breakpoint {
      padding: calc(#{$spacer} * 2) calc(#{$spacer} * 2);
    }
  }

  :host-context(.card-wrap) .mat-form {
    width: 100%;
    margin: 0;
  }
}

//Word break management for asian languages

@mixin word-break {
  word-break: break-word;

  &[lang="zh"],
  &[lang="ja"],
  &[lang="ko"] {
    word-break: keep-all;
  }
}

// Typography defaults

@mixin mat-headline {
  font-family: var(--sys-headline-large-font);
  line-height: var(--sys-headline-large-line-height);
  font-size: var(--sys-headline-large-size);
  letter-spacing: var(--sys-headline-large-tracking);
  font-weight: var(--sys-headline-large-weight);
  color: var(--ds-headline-color);
}

@mixin mat-body {
  font-family: var(--sys-body-medium-font);
  line-height: var(--sys-body-medium-line-height);
  font-size: var(--sys-body-medium-size);
  letter-spacing: var(--sys-body-medium-tracking);
  font-weight: var(--sys-body-medium-weight);
}

@mixin mat-caption {
  font-family: var(--sys-body-small-font);
  line-height: var(--sys-body-small-line-height);
  font-size: var(--sys-body-small-size);
  letter-spacing: var(--sys-body-small-tracking);
  font-weight: var(--sys-body-small-weight);
}
