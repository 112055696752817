@use "@angular/material" as mat;
@use "./theme/m3-theme" as theme;
@import "global/variables";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/// ----------------------------
/// Javascript API for the theme
/// ----------------------------
///
/// - All known `--sys` color tokens are defined in src\app\api\modules\core\components\theme\tokens\MaterialColorTokens.ts
/// - All known `--sys` typography tokens are defined in src\app\api\modules\core\components\theme\tokens\MaterialTypographyTokens.ts
/// - All known `--ds` design tokens are defined in src\app\api\modules\core\components\theme\tokens\DrimifyTokens.ts
///
/// It is important that these remain in sync with the sass.
///
/// --------------------------------------------
/// CHANGES TO src/app/style/theme/m3-theme.scss
/// --------------------------------------------
///
/// 1. Palette changes should be reflected to src\app\api\modules\core\components\theme\palettes\MaterialPalette.ts
///
/// ----------------------------------------------------
/// Light / Dark Theme and why we don't use it right now
/// ----------------------------------------------------
///
/// We no longer generate a light and dark theme.
///
/// The reason for this is because Angular Material Mixin's dont provide what we need. Angular Material uses mixins to set the
/// --sys- variables from the palette. However, it doesn't expose the palette values as variables. In a perfect world, we would
/// have access to the palette such as `--sys-ref-primary-50`. It is these which we need in order to fully utilise the system
/// design tokens.
///
/// Instead, we have to do the job of the mixin and set the system design token values ourselves by pulling the same values
/// from the design palette.
///
/// This strategy may change as references to the palette are provided in a future Angular Release.
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/*
    Theme based on Damiens Values See https://drimify.atlassian.net/browse/APP-1013
*/
$light-theme: theme.$light-theme;

// This should only ever be called once in the application
@include mat.core();

html {
  // Clark on 02/08/2024. Review this line to ensure we aren't going to include all of the components when we don't use all components.
  @include mat.all-component-themes($light-theme);

  // Generate the system design tokens from the theme
  @include mat.system-level-colors($light-theme);
  @include mat.system-level-typography($light-theme);

  // Generate the typography classes
  @include mat.all-component-typographies($light-theme);

  // Generate the typography hierarchy classes
  @include mat.typography-hierarchy($light-theme);

  // drimify custom variables declaration and defaults
  --ds-leaderboard-cell-user-highlight: rgba(0, 0, 255, 0.5);
  --ds-leaderboard-cell-change-highlight: rgba(255, 255, 255, 1);
  // --ds-dynamic-path-node-border
  // --ds-dynamic-path-svg-ling
  // --ds-radius
  // --ds-headline-large-align
  // --ds-headline-large-color
  // --ds-body-medium-align
  // --ds-body-medium-color
  // --ds-display-small-color
  // --ds-display-small-stroke-color
  // --ds-button-label-color
  // --ds-button-label-weight
  // --ds-button-texture-url

  // All remaining styles are applied to the document
  @import "global/mixins";
  @import "global/base";

  @import "custom/native-error";
  @import "custom/dialog";
  @import "custom/form";
  @import "custom/databox";
  @import "custom/snackbar";

  @import "global/typography";
}
