// @include mat.all-component-typographies($custom-typography);
//
// Global typography rules

// Default titles
.mat-headline-large,
.mat-typography .mat-headline-large {
  @include mat-headline;
  @include spacer;
  @include word-break;
  color: var(--ds-font-headline-color);
  text-align: center;

  @include breakpoint {
    //font-size: var(--sys-headline-large-size, 2rem);
  }
  //
  @media print {
    color: black !important;
  }
}

.mat-mdc-dialog-title,
.mat-typography .mat-dialog-title {
  @include word-break;
  text-align: center;
}

///////////////////////////////////////
// Default body content
.mat-body-medium,
.mat-typography .mat-body-medium {
  //     // color: var(--mat-app-text-color);
  @include mat-body;

  text-align: var(--ds-font-body-align, center);

  @include word-break;
  //     //@include block;
  @include spacer;
  //
  @media print {
    color: black !important;
  }
}

// Exception for the leaderboard
.mat-mdc-table .mat-body-medium {
  margin-bottom: 0;
}

// Default links
a {
  color: var(--mat-app-text-color);
}

// // Default caption
.mat-body-small,
.mat-typography .mat-body-small {
  //     // color: var(--mat-app-text-color);
  //font-weight: var(--sys-body-medium-weight, $mat-body-font-weight);
  //font-family: var(--sys-body-medium-font, $mat-font-family);
  //     @include block;
  @include mat-caption;
  @include spacer;
  text-align: center;
  //     line-height: 1.25;
  @include word-break;
  //
  @media print {
    color: black !important;
  }
}
//
// Extras
strong,
b {
  font-weight: 700;
}
