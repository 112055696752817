// @use './global/theme' as theme;

// Default spacer unit between components
$spacer: 1rem;

$mat-dark-color: black;
$mat-light-color: white;

$mat-color-success: #8bc34a;
$mat-color-error: #ff5722;
$mat-color-warning: #ffc107;

// Default border-radius
$mat-radius: 12px 12px 12px 12px;

// Default font settings
$mat-font-family: "Roboto", sans-serif;

$mat-headline-font-weight: 700;
//$mat-headline-font-size: 2rem; Moved to _typography.css for responsiveness

$mat-body-font-weight: 400;
$mat-body-font-size: 1rem;

// Default button
$mat-button-max-width: 400px;

// Mat Form
$mat-form-alpha: 0.5;

// Transparent Pixel 1x1
$pixel: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=);

$theme-form-background-opacity: 0.5; //Changed from var opactiy to Sasss variable
