@use "@angular/material" as mat;
@import "global/variables";

.mat-mdc-dialog-container {
  margin-right: $spacer;
  margin-left: $spacer;
}

.mat-mdc-dialog-surface {
  border-radius: var(--ds-radius, $mat-radius);
  background-color: var(--sys-surface);
  @include box-shadow;
}

// ios fix for dialog height with bottom bar
.mat-mdc-dialog-content {
  max-height: 55vh !important;

  @include breakpoint {
    max-height: 65vh !important;
    min-width: 100%;
  }
}

// Typography
.mat-mdc-dialog-title,
.mat-mdc-dialog-content {
  //  @include mat.typography-level($custom-typography, title);
  font-family: var(--sys-body-medium-font, $mat-font-family);
}

// Fix for icons in the context of a play button for a video and audio in a Dialog
.mat-mdc-dialog-content .ui .svg-inline--fa {
  vertical-align: 0 !important;
}
