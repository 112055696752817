html,
body {
  //height: 100%; removed for compatibility with widget-fixed APP-317
  margin: 0;
  padding: 0;
}

body {
  //min-height: 100vh; //Removed to fix APP-406
  min-height: -webkit-fill-available;
}

/*
  The App Native Container is part of the index.html for once the Angular App has bootstrapped.
*/
#app-native-container {
  display: flex;
  position: relative;
}
