// DO NOT IMPORT in main.scss - This custom is imported at the databox components level.

@import "global/variables";
@import "global/mixins";

:host {
  margin-bottom: calc(#{$spacer} * 0.25);
  flex-grow: 1;
  padding: calc(#{$spacer} * 0.5);
  border-radius: var(--ds-radius, $mat-radius);
  background-color: var(--sys-surface-container);
  // To center,center the divs inside the databox components and have icons on the same baseline as text.
  display: flex;
  justify-content: center;
  align-items: center;
}
